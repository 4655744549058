<template>
  <list-view
    :tableHeaders="tableHeaders"
    :formattedDataFunction="formattedDataFunction"
    :query="query"
    :bulkDeleteEnabled="false"
    :bulkEditEnabled="false"
    :listActionOptions="listActionOptions"
  >
    <template v-slot:actions>
      <!-- Create Dialog -->
      <v-dialog v-model="showDialog" max-width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="green"
            dark
            class="float-right ml-3"
            v-bind="attrs"
            v-on="on"
          >
            Create New Customer
          </v-btn>
        </template>
        <v-form v-model="isValid" ref="form">
          <v-card>
            <v-card-title class="headline"> Create New Customer </v-card-title>

            <v-card-text>
              This will kick off the function to setup a new project.

              <v-text-field
                v-model="formInput.name"
                label="Name"
                hint="The organization name."
                persistent-hint
                :rules="[rules.required, rules.projectName, rules.minLength4]"
              ></v-text-field>

              <div class="mt-2">
                Domain preview:
                <span class="font-weight-bold">{{ domainPreview }}</span>
              </div>

              <v-checkbox
                v-model="formInput.overrideProjectId"
                label="Override Project ID"
              ></v-checkbox>

              <v-text-field
                v-if="formInput.overrideProjectId"
                v-model="formInput.projectId"
                label="Project ID"
                :rules="[
                  rules.required,
                  rules.projectId,
                  rules.minLength6,
                  rules.maxLength30,
                  rules.startWithLowercase,
                  rules.endWithNoHyphen,
                ]"
              ></v-text-field>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn @click="showDialog = false" text color="gray"
                >Cancel</v-btn
              >

              <v-btn
                @click="submitForm"
                color="green darken-1"
                text
                :loading="isLoading"
              >
                Create Customer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </template>
  </list-view>
</template>

<script>
import { db } from "../../../firebase";
import { timestampToString } from "../../../utils/timing";
import { generateHandle } from "../../../utils/text";
import ListView from "../../components/ListView.vue";
import { triggerSetupProject } from "../../../utils/callableFunctions";

export default {
  components: { ListView },
  data: () => ({
    tableHeaders: [
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Handle",
        value: "handle",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Created",
        value: "created",
        filterable: false,
      },
    ],
    formattedDataFunction: (item) => {
      const formattedData = {
        ...item,
        id: item.id,
        created: timestampToString(item.created),
      };
      return formattedData;
    },
    query: db.collection("customers").orderBy("created", "desc"),
    listActionOptions: {
      enableCreate: false,
      enableExport: false,
    },
    showDialog: false,
    isValid: false,
    isLoading: false,
    formInput: {
      name: "",
      overrideProjectId: false,
      projectId: "",
    },
    rules: {
      required: (v) =>
        (!!v && (typeof v === "string" ? !!v.trim() : true)) || "Required",
      projectId: (v) =>
        /^[a-z0-9-]+$/.test(v) ||
        "May only contain lowercase letters, numbers, and hyphens",
      startWithLowercase: (v) =>
        /^[a-z]+$/.test(v.slice(0, 1)) || "Must start with a lowercase letter",
      endWithNoHyphen: (v) =>
        v.slice(-1) !== "-" || "May not end with a hyphen",
      projectName: (v) =>
        /^[a-zA-Z0-9-!'"\s]+$/.test(v) ||
        `May only contain letters, numbers, spaces, and these characters: -!'"`,
      maxLength30: (v) =>
        v.length <= 30 || "Cannot be longer than 30 characters",
      minLength4: (v) => v.length >= 4 || "Must be at least 4 characters long",
      minLength6: (v) => v.length >= 6 || "Must be at least 6 characters long",
    },
  }),
  computed: {
    domainPreview() {
      let handle = generateHandle(this.formInput.name);
      return this.formInput.name
        ? `${handle}.takeaction.co`
        : "[name].takeaction.co";
    },
  },
  methods: {
    submitForm() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;

        return triggerSetupProject(
          this.formInput.name,
          this.formInput.projectId
        )
          .then(() => {
            this.showDialog = false;
            this.isLoading = false;
            this.$refs.form.resetValidation();
            this.formInput.name = "";
            this.formInput.overrideProjectId = false;
            this.formInput.projectId = "";
            this.$toast.show({ text: "Customer created." });
          })
          .catch((error) => {
            console.error("Error creating customer: ", error);
          });
      }
    },
  },
};
</script>
