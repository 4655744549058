import { unparse } from "papaparse";
import { saveAs } from "file-saver";

export function downloadCsv(data: any, filename = "export.csv") {
  // Convert JSON to CSV
  const csv = unparse(data);

  // Download File
  const blob = new Blob([csv], {
    type: "text/plain;charset=utf-8",
  });
  saveAs(blob, filename);
}
