import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Toast from "./plugins/toast";
import { auth, db } from "./firebase";
import { firestorePlugin } from "vuefire";

Vue.use(firestorePlugin);
Vue.use(Toast);

Vue.config.productionTip = false;

let app: Vue;

// Check if user is authenticated
auth.onAuthStateChanged(async (user) => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  }

  if (user) {
    // Bind Firestore user data to store
    store.dispatch("bindUser", { ref: db.doc(`users/${user.uid}`) });
  }
});
