import { titleCase } from "../utils/text";

const initialState = () => {
  return {
    collectionName: "",
    queryCollection: null,
    selectedRows: [],
    bulkDeleteEnabled: true,
    bulkEditEnabled: true,
    showBulkEdit: false,
    searchText: null,
  };
};

export const admin = {
  namespaced: true,
  state: initialState(),
  getters: {
    collectionNameTitle(state: any) {
      return titleCase(state.collectionName);
    },
    collectionDetailName(state: any, getters: any) {
      const lastLetter = state.collectionName.slice(-1).toLowerCase();

      // If plural, return title case singular version
      if (lastLetter === "s") {
        return getters.collectionNameTitle.substring(
          0,
          state.collectionName.length - 1
        );
      } else {
        return state.collectionName;
      }
    },
    selectedLength(state: any) {
      return state.selectedRows.length;
    },
    isSelected(state: any, getters: any) {
      return getters.selectedLength > 0;
    },
    collectionNameText(state: any, getters: any) {
      if (getters.selectedLength === 1) {
        return getters.collectionDetailName.toLowerCase();
      } else {
        return state.collectionName.toLowerCase();
      }
    },
  },
  mutations: {
    setCollectionName(state: any, payload: any) {
      state.collectionName = payload;
    },
    setQueryCollection(state: any, payload: any) {
      state.queryCollection = payload;
    },
    setSelectedRows(state: any, payload: any) {
      state.selectedRows = payload;
    },
    setBulkDeleteEnabled(state: any, payload: any) {
      state.bulkDeleteEnabled = payload;
    },
    setBulkEditEnabled(state: any, payload: any) {
      state.bulkEditEnabled = payload;
    },
    setShowBulkEdit(state: any, payload: any) {
      state.showBulkEdit = payload;
    },
    setSearchText(state: any, payload: any) {
      state.searchText = payload;
    },
  },
};
