export function titleCase(text: string) {
  if (!text) {
    return "";
  }

  const sentence = text.toLowerCase().split(" ");
  for (let i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }
  return sentence.join(" ");
}

export function capitalizeFirstLetter(text: string) {
  if (!text) {
    return "";
  }

  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function fullName(firstName: string, lastName: string) {
  let name = "";
  if (firstName && lastName) {
    name = `${firstName} ${lastName}`;
  } else if (firstName) {
    name = firstName;
  } else if (lastName) {
    name = lastName;
  }
  return name;
}

// This function also exists in the functions directory, so any changes made here should be made there as well
export function generateHandle(name: string) {
  // Replace spaces, !, ", and ' with a hyphen
  let handle = name
    .replace(/\s/g, "-")
    .replace(/!/g, "-")
    .replace(/'/g, "-")
    .replace(/"/g, "-")
    .replace(/--+/g, "-");

  // Remove first hyphen
  handle = handle.slice(0, 1) === "-" ? handle.slice(1) : handle;

  // Remove last hyphen
  handle = handle.slice(-1) === "-" ? handle.slice(0, -1) : handle;

  // Make everything lowercase
  handle = handle.toLowerCase();

  return handle;
}
