<template>
  <div>
    <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
    <v-container fluid v-if="doc" class="pt-1">
      <v-row>
        <v-col cols="12" sm="6" class="pb-2">
          <v-btn
            :to="{ name: collectionDetailName + ' List' }"
            icon
            plain
            small
            class="mr-2 mb-2"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <slot name="title" :doc="doc">
            <span class="text-h5 font-weight-medium">
              {{ isNew ? `New ${collectionDetailName}` : doc.name }}
            </span>
          </slot>
        </v-col>
        <v-col cols="12" sm="6" align-self="end">
          <v-btn
            v-if="!isNew && enableDuplicate"
            @click="duplicateDoc"
            text
            color="gray"
            class="float-right ml-3"
          >
            <v-icon left small>mdi-content-copy</v-icon>
            Duplicate
          </v-btn>

          <v-btn
            v-if="toggleEdit && !toggleEditLocal"
            @click="toggleEditOn"
            text
            color="gray"
            class="float-right"
          >
            <v-icon left small>mdi-pencil</v-icon>
            Edit
          </v-btn>
          <slot name="actions" :doc="doc"></slot>
        </v-col>
      </v-row>

      <v-form v-model="isValid" ref="form">
        <v-row class="mt-0">
          <slot name="content" :doc="doc"></slot>
        </v-row>

        <v-divider
          v-if="enableEdit || toggleEditLocal"
          class="mb-4 mt-6"
        ></v-divider>

        <v-row v-if="enableEdit || toggleEditLocal">
          <v-col>
            <v-btn
              @click="saveDoc"
              :loading="isDisabled"
              color="indigo"
              dark
              class="mr-3"
            >
              <v-icon left dense>mdi-content-save</v-icon>
              Save
            </v-btn>

            <!-- Create Doc: Discard -->
            <v-btn
              v-if="isNew"
              :to="{ name: collectionDetailName + ' List' }"
              text
              color="gray"
            >
              Discard
            </v-btn>

            <!-- Edit Doc: Delete -->
            <v-btn
              v-if="!isNew"
              @click.stop="dialog = true"
              color="red"
              outlined
            >
              <v-icon left dense>mdi-delete</v-icon>
              Delete
            </v-btn>
            <dialog-confirm
              v-model="dialog"
              title="Are you sure?"
              :text="`This will permanently delete this ${collectionDetailName.toLowerCase()} and all associated data.`"
              @confirm-action="deleteDoc"
            ></dialog-confirm>

            <v-slide-y-transition>
              <div
                v-if="!isValid && displayError"
                class="error--text text-caption pt-2"
              >
                There are some errors. Check above for things in red.
              </div>
            </v-slide-y-transition>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import firebase, { db } from "../../firebase";
import DialogConfirm from "@/components/DialogConfirm";

const { mapState, mapGetters } = createNamespacedHelpers("admin");

export default {
  components: {
    DialogConfirm,
  },
  props: {
    initialValues: Object,
    queryCollectionOverride: String,
    docIdOverride: String,
    duplicatedFieldsOverride: Object,
    saveMeta: {
      type: Boolean,
      default: false,
    },
    enableEdit: {
      type: Boolean,
      default: true,
    },
    enableDuplicate: {
      type: Boolean,
      default: false,
    },
    toggleEdit: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    doc: null,
    loading: true,
    isValid: false,
    isDisabled: false,
    displayError: false,
    dialog: false,
    toggleEditLocal: false,
  }),
  computed: {
    ...mapState(["collectionName", "queryCollection"]),
    ...mapGetters(["collectionNameTitle", "collectionDetailName"]),
    isNew() {
      return !(this.$route.params.id || this.docIdOverride);
    },
  },
  methods: {
    saveDoc() {
      if (this.$refs.form.validate()) {
        this.isDisabled = true;
        const actionText = this.isNew ? "creat" : "updat";

        // Save metadata if needed
        if (this.saveMeta) {
          this.doc.lastUpdated =
            firebase.firestore.FieldValue.serverTimestamp();
        }

        // Extract the doc id, may be undefined
        const { id, ...rest } = this.doc;

        // Save to Firestore
        db.collection(this.queryCollection)
          .doc(id || undefined)
          .set(rest)
          .then(() => {
            this.$toast.show({
              text: this.collectionDetailName + ` ${actionText}ed.`,
            });
            this.$router.push({ name: this.collectionDetailName + " List" });
          })
          .catch((error) => {
            console.error(`Error ${actionText}ing document: `, error);
          });
      } else {
        this.displayError = true;
      }
    },
    deleteDoc() {
      db.collection(this.queryCollection)
        .doc(this.doc.id)
        .delete()
        .then(() => {
          this.$toast.show({
            text: this.collectionDetailName + " deleted.",
          });
          this.$router.push({ name: this.collectionDetailName + " List" });
        })
        .catch((error) => {
          console.error("Error deleting document: ", error);
        });
    },
    duplicateDoc() {
      let standardDuplicatedFields = {};
      if (this.doc.name) {
        standardDuplicatedFields = { name: `Copy of ${this.doc.name}` };
      }
      this.doc = {
        ...this.doc,
        ...standardDuplicatedFields,
        ...this.duplicatedFieldsOverride,
      };
      this.$router.push({ name: this.collectionDetailName + " Create" });
    },
    toggleEditOn() {
      this.toggleEditLocal = true;
      this.$emit("toggleEditOn", this.toggleEditLocal);
    },
  },
  async created() {
    // Calculate the collection name from the path and commit to store
    // e.g. /admin/{{x}}/id where x is the collection name
    this.$store.commit(
      "admin/setCollectionName",
      this.$route.path.split("/")[2]
    );

    // Set query collection in the store
    this.$store.commit(
      "admin/setQueryCollection",
      this.queryCollectionOverride || this.collectionName
    );

    // Bind document if it exists
    if (this.$route.params.id || this.docIdOverride) {
      await this.$bind(
        "doc",
        db
          .collection(this.queryCollection)
          .doc(this.$route.params.id || this.docIdOverride)
      );
    } else {
      this.doc = this.initialValues;
    }

    this.loading = false;
  },
};
</script>
