import store from "../store";

export default {
  install(Vue: any) {
    Vue.prototype.$toast = {
      show({ text = "", color = "" }) {
        store.commit("toast/show", { text, color });
      },
    };
  },
};
