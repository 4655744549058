import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "../store";
import { auth } from "../firebase";

// Views
import SignIn from "@/views/SignIn.vue";
import Home from "../views/Home.vue";

// Admin Views
import CustomerList from "@/admin/views/customers/CustomerList.vue";
import CustomerDetail from "@/admin/views/customers/CustomerDetail.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/signin",
    name: "Sign In",
    component: SignIn,
    meta: {
      hideNavigation: true,
    },
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/customers",
    name: "Customer List",
    component: CustomerList,
  },
  {
    path: "/admin/customers/:id",
    name: "Customer Detail",
    component: CustomerDetail,
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const currentUser = auth.currentUser;
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);

  // Reset batch selected rows if there are any
  if (store.getters["admin/isSelected"]) {
    store.commit("admin/setSelectedRows", []);
  }

  // Set page title
  document.title = `${to.name}`;

  // User is not authenticaticated
  if (requiresAuth && !currentUser) {
    // Set the URL the user first tried to access so FirebaseUI
    // can redirect to it after they login. Also remove starting slash.
    const redirectUrl = window.location.pathname.substring(1);

    // Set the route object
    const route = {
      name: "Sign In",
      query: redirectUrl ? { signInSuccessUrl: redirectUrl } : undefined,
    };

    next(route);
  }

  // Authenticated user is attempting to visit sign in page
  else if (to.name === "Sign In" && currentUser) {
    next({ name: "Home" });
  }

  // User is logged in
  else if (requiresAuth && currentUser) {
    next();
  }

  // Everything else
  else {
    next();
  }
});

export default router;
