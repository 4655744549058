<template>
  <v-dialog v-model="show" max-width="290">
    <v-card>
      <v-card-title class="headline">
        {{ title }}
      </v-card-title>

      <v-card-text>
        {{ text }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="green darken-1"
          text
          @click="confirmAction"
          :disabled="isDisabled"
          >Yes</v-btn
        >

        <v-btn color="red darken-1" text @click="show = false"> No </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    isDisabled: false,
  }),
  props: {
    value: Boolean,
    title: String,
    text: String,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    confirmAction() {
      this.isDisabled = true;
      this.$emit("confirm-action");
    },
  },
};
</script>
