const initialState = () => {
  return {
    text: "",
    color: "",
  };
};

export const toast = {
  namespaced: true,
  state: initialState(),
  mutations: {
    show(state: any, payload: any) {
      (state.text = payload.text), (state.color = payload.color);
    },
  },
};
