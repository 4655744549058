<template>
  <div>
    <v-btn
      v-if="enableCreate"
      :to="{ name: collectionDetailName + ' Create' }"
      color="indigo"
      dark
      class="float-right ml-3"
    >
      Add {{ collectionDetailName }}
    </v-btn>

    <slot name="actions"></slot>

    <v-btn
      v-if="enableExport"
      @click="exportRows()"
      text
      color="gray"
      class="float-right"
    >
      Export {{ isSelected ? "Selected" : "" }}
    </v-btn>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { downloadCsv } from "../utils/export";

const { mapState, mapGetters } = createNamespacedHelpers("admin");

export default {
  props: {
    allData: Array,
    enableCreate: {
      type: Boolean,
      default: true,
    },
    enableExport: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState(["selectedRows"]),
    ...mapGetters(["collectionDetailName", "selectedLength", "isSelected"]),
    exportData() {
      return this.isSelected ? this.selectedRows : this.allData;
    },
  },
  methods: {
    exportRows() {
      downloadCsv(this.exportData);
    },
  },
};
</script>
