<template>
  <v-snackbar v-model="show" :color="color" :timeout="7000" class="pb-10">
    {{ text }}
    <template v-slot:action="{ attrs }">
      <v-btn icon color="white" v-bind="attrs" @click="show = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      text: "",
      color: "",
    };
  },

  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "toast/show") {
        this.text = state.toast.text;
        this.color = state.toast.color;
        this.show = true;
      }
    });
  },
};
</script>
