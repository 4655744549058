import { db } from "../../firebase";
import store from "../../store";

export async function batchUpdate(changes: any) {
  const queryCollection = store.state.admin.queryCollection;
  const selectedRows = store.state.admin.selectedRows;

  // Get a new write batch
  const batch = db.batch();

  // Loop over documents and push job
  selectedRows.forEach((doc: any) => {
    batch.update(db.collection(queryCollection).doc(doc.id), changes);
  });

  // Write to Firestore
  try {
    return batch.commit();
  } catch (error) {
    console.error("Error updating documents: ", error);
  }
}

export async function batchDelete() {
  const queryCollection = store.state.admin.queryCollection;
  const selectedRows = store.state.admin.selectedRows;

  // Get a new write batch
  const batch = db.batch();

  // Loop over documents and push job
  selectedRows.forEach((doc: any) => {
    batch.delete(db.collection(queryCollection).doc(doc.id));
  });

  // Write to Firestore
  try {
    return batch.commit();
  } catch (error) {
    console.error("Error deleting documents: ", error);
  }
}
