

















export default {
  data: () => ({
    selectedItem: 1,
    mainMenu: [
      {
        text: "Home",
        icon: "mdi-home",
        path: "/",
      },
      {
        text: "Customers",
        icon: "mdi-account-multiple",
        path: "/admin/customers",
      },
    ],
  }),
};
