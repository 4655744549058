var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('list-view',{attrs:{"tableHeaders":_vm.tableHeaders,"formattedDataFunction":_vm.formattedDataFunction,"query":_vm.query,"bulkDeleteEnabled":false,"bulkEditEnabled":false,"listActionOptions":_vm.listActionOptions},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"float-right ml-3",attrs:{"color":"green","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Create New Customer ")])]}}]),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-form',{ref:"form",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Create New Customer ")]),_c('v-card-text',[_vm._v(" This will kick off the function to setup a new project. "),_c('v-text-field',{attrs:{"label":"Name","hint":"The organization name.","persistent-hint":"","rules":[_vm.rules.required, _vm.rules.projectName, _vm.rules.minLength4]},model:{value:(_vm.formInput.name),callback:function ($$v) {_vm.$set(_vm.formInput, "name", $$v)},expression:"formInput.name"}}),_c('div',{staticClass:"mt-2"},[_vm._v(" Domain preview: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.domainPreview))])]),_c('v-checkbox',{attrs:{"label":"Override Project ID"},model:{value:(_vm.formInput.overrideProjectId),callback:function ($$v) {_vm.$set(_vm.formInput, "overrideProjectId", $$v)},expression:"formInput.overrideProjectId"}}),(_vm.formInput.overrideProjectId)?_c('v-text-field',{attrs:{"label":"Project ID","rules":[
                _vm.rules.required,
                _vm.rules.projectId,
                _vm.rules.minLength6,
                _vm.rules.maxLength30,
                _vm.rules.startWithLowercase,
                _vm.rules.endWithNoHyphen ]},model:{value:(_vm.formInput.projectId),callback:function ($$v) {_vm.$set(_vm.formInput, "projectId", $$v)},expression:"formInput.projectId"}}):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"gray"},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"green darken-1","text":"","loading":_vm.isLoading},on:{"click":_vm.submitForm}},[_vm._v(" Create Customer ")])],1)],1)],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }