












import Vue from "vue";
import { db } from "../firebase";

export default Vue.extend({
  name: "Home",
  data: () => ({
    customers: [],
    appVersion: process.env.VUE_APP_VERSION,
  }),
  async created() {
    // Bind Customers
    await this.$bind("customers", db.collection("customers"));
  },
});
