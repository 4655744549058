
























import Vue from "vue";
import Navigation from "@/components/Navigation.vue";
import AppBar from "@/components/AppBar.vue";
import Toast from "@/components/Toast.vue";

export default Vue.extend({
  name: "App",
  components: {
    Navigation,
    AppBar,
    Toast,
  },
  data: () => ({
    drawer: null,
  }),
});
