<template>
  <v-app-bar
    v-if="!$route.meta.hideNavigation"
    :color="isSelected ? 'black' : 'primary'"
    dark
    app
    clipped-left
  >
    <v-app-bar-nav-icon
      v-if="!isSelected"
      @click.stop="$emit('toggle-drawer')"
    />
    <v-btn v-else icon @click="resetSelected">
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <v-toolbar-title v-if="!isSelected" class="pl-2">
      Firebuilders Console
    </v-toolbar-title>
    <v-toolbar-title v-else class="pl-2">
      {{ selectedLength }} selected
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <transition name="slide-y-transition">
      <span v-if="isSelected">
        <v-btn v-if="bulkDeleteEnabled" @click.stop="dialog = true" icon>
          <v-icon>mdi-delete</v-icon>
        </v-btn>

        <v-btn v-if="bulkEditEnabled" @click.stop="editSelected" icon>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </span>
    </transition>

    <user-menu v-if="!isSelected"></user-menu>

    <dialog-confirm
      v-model="dialog"
      title="Are you sure?"
      :text="`This will permanently delete ${
        selectedLength === 1
          ? `this ${collectionNameText}`
          : `these ${selectedLength} ${collectionNameText}`
      } and all associated data.`"
      @confirm-action="batchDelete"
    ></dialog-confirm>
  </v-app-bar>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import UserMenu from "./UserMenu";
import DialogConfirm from "@/components/DialogConfirm";
import { batchDelete } from "../admin/utils/batch";

const { mapState, mapGetters } = createNamespacedHelpers("admin");

export default {
  components: {
    DialogConfirm,
    UserMenu,
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    ...mapState(["bulkDeleteEnabled", "bulkEditEnabled"]),
    ...mapGetters(["collectionNameText", "selectedLength", "isSelected"]),
  },
  methods: {
    resetSelected() {
      this.$store.commit("admin/setSelectedRows", []);
    },
    editSelected() {
      this.$store.commit("admin/setShowBulkEdit", true);
    },
    async batchDelete() {
      await batchDelete();
      this.$toast.show({
        text: `${this.selectedLength} ${this.collectionNameText} deleted.`,
      });
      this.dialog = false;
      this.resetSelected();
    },
  },
};
</script>
