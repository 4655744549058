import { auth } from "../firebase";

export function isFirebuildersUser(): boolean {
  const currentUser = auth.currentUser;

  if (currentUser) {
    const googleProvider = currentUser.providerData.find(
      (provider: any) => provider.providerId === "google.com"
    );

    if (googleProvider?.email) {
      return !!googleProvider.email.match(
        /[a-zA-Z0-9+._-]+@firebuilders[.]io$/
      );
    }
  }

  return false;
}
