<template>
  <detail-view :enableEdit="enableEdit">
    <!-- Content -->
    <template v-slot:content="{ doc }">
      <v-col>
        <!-- General -->
        <v-card>
          <v-card-title>General</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Handle</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ doc.handle }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>Domain</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ doc.domain }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <!-- Setup -->
        <v-card class="mt-6">
          <v-card-title>Setup</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-list-item
                  v-for="step in doc.config.setup"
                  :key="step.name"
                  two-line
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ step.name }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ step.status }} as of
                      {{ timestampToString(step.lastUpdated) }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ step.error }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Aside -->
      <v-col cols="12" md="4" xl="3">
        <v-card>
          <v-card-title>Meta</v-card-title>
          <v-card-text class="px-0">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Name</v-list-item-title>
                <v-list-item-subtitle>{{ doc.name }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Status</v-list-item-title>
                <v-list-item-subtitle>{{ doc.status }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Project ID</v-list-item-title>
                <v-list-item-subtitle>{{ doc.id }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Project Number</v-list-item-title>
                <v-list-item-subtitle>
                  {{ doc.projectNumber }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Schema Version</v-list-item-title>
                <v-list-item-subtitle>
                  {{ doc.config.schemaVersion }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Created</v-list-item-title>
                <v-list-item-subtitle>
                  {{ timestampToString(doc.created) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>
    </template>
  </detail-view>
</template>

<script>
import DetailView from "../../components/DetailView.vue";
import { isFirebuildersUser } from "../../../utils/auth";
import { timestampToString } from "../../../utils/timing";

export default {
  components: {
    DetailView,
  },
  data: () => ({
    enableEdit: false,
  }),
  computed: {
    isFirebuildersUser() {
      return isFirebuildersUser();
    },
  },
  methods: {
    timestampToString(timestamp) {
      return timestampToString(timestamp);
    },
  },
};
</script>
