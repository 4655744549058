import Vue from "vue";
import { vuexfireMutations, firestoreAction } from "vuexfire";
import Vuex from "vuex";
import router from "../router";
import { auth } from "../firebase";

// Modules
import { admin } from "./admin";
import { toast } from "./toast";

Vue.use(Vuex);

export default new Vuex.Store<any>({
  state: {
    user: null,
  },
  mutations: {
    ...vuexfireMutations,
  },
  actions: {
    bindUser: firestoreAction(({ bindFirestoreRef }, { ref }) => {
      return bindFirestoreRef("user", ref);
    }),
    unbindUser: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef("user");
    }),
    async signOutAction({ dispatch }: { dispatch: any }) {
      // Sign out from Firebase Auth
      await auth.signOut();

      // Clear state
      dispatch("unbindUser");

      // Redirect
      router.push({ name: "Sign In" });
    },
  },
  modules: {
    admin: admin,
    toast: toast,
  },
});
