import firebase from "@/firebase";

export function timestampToString(
  timestamp: firebase.firestore.Timestamp,
  defaultString = "-"
) {
  if (timestamp && timestamp.seconds) {
    return timestamp?.toDate().toLocaleString() || defaultString;
  } else {
    return defaultString;
  }
}
