import firebase from "../firebase";

export async function triggerSetupProject(
  customerName: string,
  projectId = ""
) {
  const setupProject = firebase.functions().httpsCallable("setupProject");
  try {
    await setupProject({ customerName: customerName, projectId: projectId });
    console.log(`Project setup successfully initialized.`);
  } catch (error: any) {
    const code = error.code;
    const message = error.message;
    const details = error.details;
    console.error(`ERROR ${code}: ${message}. ${details}`);
  }
}
