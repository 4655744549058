<template>
  <v-menu v-if="user" bottom rounded offset-y nudge-bottom="24">
    <template v-slot:activator="{ on }">
      <v-btn icon large v-on="on">
        <v-avatar v-if="user.photoURL" :size="avatarSize">
          <img :src="user.photoURL" alt="User photo" />
        </v-avatar>
        <v-avatar v-else color="primary darken-4" :size="avatarSize">
          <span class="white--text">{{ initials }}</span>
        </v-avatar>
      </v-btn>
    </template>
    <v-card>
      <v-list-item-content class="justify-center pb-0">
        <div class="mx-auto text-center">
          <v-avatar v-if="user.photoURL" :size="avatarSize">
            <img :src="user.photoURL" :alt="fullName" />
          </v-avatar>
          <v-avatar v-else color="primary darken-4" :size="avatarSize">
            <span class="white--text">{{ initials }}</span>
          </v-avatar>

          <h4 v-if="fullName" class="mt-2">{{ fullName }}</h4>
          <p class="caption">
            {{ user.email }}
          </p>
        </div>

        <v-list class="pb-0">
          <!-- Sign Out -->
          <v-list-item @click.prevent="signOut" to="signout" exact>
            <v-list-item-icon>
              <v-icon>mdi-export</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Sign Out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-list-item-content>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { fullName } from "../utils/text";

export default {
  data: () => ({
    avatarSize: 44,
  }),
  computed: {
    ...mapState(["user"]),
    fullName() {
      return fullName(this.user.firstName, this.user.lastName);
    },
    initials() {
      const nameInitials = fullName(
        this.user.firstName[0],
        this.user.lastName[0]
      );
      return nameInitials || this.user.email[0];
    },
  },
  methods: {
    ...mapActions({ signOut: "signOutAction" }),
  },
};
</script>
