<template>
  <v-container fluid class="px-0 px-sm-3 pt-0">
    <v-container fluid class="pt-0 px-0">
      <v-row>
        <v-col cols="12" sm="6" align-self="end" class="pb-0 pb-sm-3">
          <span class="text-h5 font-weight-medium">
            {{ collectionNameTitle }}
          </span>
        </v-col>
        <v-col cols="12" sm="6" align-self="end">
          <list-actions :allData="formattedData" v-bind="listActionOptions">
            <template
              v-for="(index, name) in $scopedSlots"
              v-slot:[name]="data"
            >
              <slot :name="name" v-bind="data"></slot>
            </template>
          </list-actions>
        </v-col>
      </v-row>
    </v-container>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="searchText"
          prepend-inner-icon="mdi-magnify"
          :label="`Filter ${collectionName.toLowerCase()}`"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-model="selectedRows"
        :headers="tableHeaders"
        :items="formattedData"
        :items-per-page="25"
        :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100, 500] }"
        :search="searchText"
        :loading="loading"
        @click:row="detailsPage"
        class="data-table"
        :show-select="bulkDeleteEnabled || bulkEditEnabled || enableExport"
        :sort-by="sortBy"
      >
        <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
          <slot :name="name" v-bind="data"></slot>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import ListActions from "./ListActions.vue";

const { mapState, mapGetters } = createNamespacedHelpers("admin");

export default {
  components: { ListActions },
  props: {
    tableHeaders: Array,
    formattedDataFunction: Function,
    queryCollectionOverride: String,
    query: Object,
    listActionOptions: Object,
    bulkDeleteEnabled: {
      type: Boolean,
      default: true,
    },
    bulkEditEnabled: {
      type: Boolean,
      default: true,
    },
    sortBy: String,
  },
  data: () => ({
    docs: [],
    loading: true,
  }),
  computed: {
    ...mapState(["collectionName"]),
    ...mapGetters(["collectionNameTitle", "collectionDetailName"]),
    selectedRows: {
      set(rows) {
        this.$store.commit("admin/setSelectedRows", rows);
      },
      get() {
        return this.$store.state.admin.selectedRows;
      },
    },
    searchText: {
      set(text) {
        this.$store.commit("admin/setSearchText", text);
      },
      get() {
        return this.$store.state.admin.searchText;
      },
    },
    formattedData() {
      return this.docs.map(this.formattedDataFunction);
    },
    enableExport() {
      return this.listActionOptions?.enableExport === undefined
        ? true
        : this.listActionOptions.enableExport;
    },
  },
  methods: {
    detailsPage(item) {
      this.$router.push({
        name: `${this.collectionDetailName} Detail`,
        params: { id: item.id },
      });
    },
  },
  watch: {
    // Reset search text in the store whenever the active collection changes
    collectionName() {
      this.$store.commit("admin/setSearchText", "");
    },
  },
  async created() {
    // Calculate the collection name from the path and commit to store
    // e.g. /admin/{{x}}/id where x is the collection name
    this.$store.commit(
      "admin/setCollectionName",
      this.$route.path.split("/")[2]
    );

    // Set query collection in the store
    this.$store.commit(
      "admin/setQueryCollection",
      this.queryCollectionOverride || this.collectionName
    );

    // Set bulk states in the store
    this.$store.commit("admin/setBulkDeleteEnabled", this.bulkDeleteEnabled);
    this.$store.commit("admin/setBulkEditEnabled", this.bulkEditEnabled);

    // Bind Docs
    await this.$bind("docs", this.query);
    this.loading = false;
  },
};
</script>

<style scoped>
.data-table >>> tbody tr :hover {
  cursor: pointer;
}

.data-table >>> .v-data-footer__icons-after {
  margin-right: 52px;
}
</style>
