import firebase from "../firebase";

// Configure FirebaseUI
export const uiConfig = {
  signInFlow: "popup",
  signInSuccessUrl: "/",
  // Auth providers to display
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
  // Terms of service url
  tosUrl: "/",
  // Privacy policy url
  privacyPolicyUrl: "/",
};

export default uiConfig;
